import React from "react"

const Capabilities = ({ data: { capabilitiesTitle, capabilitiesList } }) => {
  return (
    <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0">
      <h2 className="uppercase text-2xl md:text-4xl md:text-center mb-4 text-white font-bold siteFont">
        {capabilitiesTitle}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-0 mx-auto">
        {capabilitiesList.map((val, i) => {
          return (
            <div
              key={val.id}
              className={`${
                i === 0
                  ? "md:border-r md:border-b"
                  : i === 1
                  ? "md:border-b"
                  : i === 2
                  ? "md:border-l md:border-b"
                  : i === 3
                  ? "md:border-r md:border-"
                  : i === 5
                  ? "md:border-l"
                  : null
              } row-span-1 col-span-1 md:text-center border-gray-800 md:py-10 md:px-1`}
            >
              <h3 className="text-xl md:text-2xl mb-3 capitalize text-white font-bold siteFont">
                {val.title}
              </h3>
              <ul className="Gray md:text-lg">
                {val.items.map((item, id) => (
                  <li key={id} className="my-3">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Capabilities
