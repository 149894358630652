import React from "react"
import { Link } from "gatsby"
import RichTextRenderer from "../../RichTextRenderer"

// Background Image component
import Background from "../../backgroundImage"

const DetailsSection2 = ({
  data: { detailsTitle, detailDescription1, detailDescription2 },
}) => {
  return (
    <Background type="greyInverse" className="my-6">
      <div className="max-w-6xl mx-auto py-8 px-4 md:px-8 xl:px-0">
        <h2 className="pt-4 md:py-4 text-white text-xl md:text-3xl font-semibold md:font-bold md:text-center md:mx-auto siteFont">
          {detailsTitle}
        </h2>
        <div className="w-full md:flex Gray md:text-lg">
          <div className="md:w-1/2 pt-4 md:mr-2">
            {RichTextRenderer(detailDescription1.json)}
          </div>
          <div className="md:w-1/2 pt-4 Gray md:ml-2">
            {RichTextRenderer(detailDescription2.json)}
          </div>
        </div>
        <Link
          to="/contact"
          className="w-auto block text-center mx-auto text-white capitalize rounded-full text-lg md:text-2xl focus:outline-none py-2 md:py-4 px-8 my-8 lg:w-1/4 bg-orange siteFont hover:bg-darkOrange transition duration-300 ease-in-out"
        >
          get started now
        </Link>
      </div>
    </Background>
  )
}

export default DetailsSection2
