import React from "react"
import Img from "gatsby-image"

const Items = ({ data }) => {
  return (
    <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0">
      <hr className="my-6 md:my-12 xl:mx-auto" />

      <h1 className="text-white font-bold text-2xl md:text-4xl text-center md:text-left uppercase mb-12 siteFont">
        Our Approach
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-6">
        {data.map(val => {
          return (
            <div
              key={val.id}
              className="grid grid-rows-2 row-gap-2 sm:row-gap-0 md:items-center md:text-center"
            >
              <div className="w-24 md:w-1/2 md:mx-auto">
                <Img fixed={val.image.fixed} alt={val.image.title} />
              </div>
              <div>
                <h3 className="text-xl md:text-2xl text-white font-bold siteFont mb-2 md:mb-4">
                  {val.title}
                </h3>
                <p className="Gray md:text-center md:text-lg">
                  {val.description.description}
                </p>
              </div>
            </div>
          )
        })}
      </div>
      <hr className="my-6 md:my-16 xl:mx-auto" />
    </div>
  )
}

export default Items
