import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Banner from "../../components/Services/Marketing/banner"
import Items from "../../components/Services/Marketing/items"
import DetailsSection1 from "../../components/Services/Marketing/detailsSection1"
import Capabilities from "../../components/Services/Marketing/capabilities"
import DetailsSection2 from "../../components/Services/Marketing/detailsSection2"
import OurWork from "../../components/Services/Marketing/ourWork"
import Slider from "../../components/slider"
import ContactUs from "../../components/contactUs"
import Estimator from "../../components/PricingCalculator/estimator"

// Context API data
import { AppContext } from "../../store/AppContext"

const MarketingPage = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    bannerDescription,
    qualities,
    title,
    subtitle,
    description,
    capabilitiesTitle,
    capabilitiesList,
    detailsTitle,
    detailDescription1,
    detailDescription2,
    selectedWorkTitle,
    selectedWork,
  } = data.marketing

  const bannerData = {
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    bannerDescription,
  }
  const detail1 = { title, subtitle, description }
  const detail2 = { detailsTitle, detailDescription1, detailDescription2 }
  const showcaseWork = { selectedWorkTitle, selectedWork }

  return (
    <Layout>
      <SEO
        title={ogTitle || "Marketing &amp; Branding"}
        description={ogDescription}
      />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <Items data={qualities} />
      <DetailsSection1 data={detail1} />
      <Capabilities data={{ capabilitiesTitle, capabilitiesList }} />
      <DetailsSection2 data={detail2} />
      <OurWork data={showcaseWork} />
      <Estimator singleService service="eCommerce_Marketing" fullyBooked />
      <Slider blueBackground />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query MarketingPageQuery {
    marketing: contentfulServicesMarketingPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerImage {
        title
        file {
          url
        }
      }

      bannerDescription {
        json
      }
      qualities {
        id
        title
        description {
          description
        }
        image {
          title
          fixed(width: 135) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      title
      subtitle
      description {
        json
      }
      capabilitiesTitle
      capabilitiesList {
        id
        title
        items
      }
      detailsTitle
      detailDescription1 {
        json
      }
      detailDescription2 {
        json
      }
      selectedWorkTitle
      selectedWork {
        id
        slug
        image {
          id
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

export default MarketingPage
