import React from "react"
import RichTextRenderer from "../../RichTextRenderer"
import { BLOCKS } from "@contentful/rich-text-types"

const options = {
  renderNode: {
    [BLOCKS.HEADING_4]: (_, children) => {
      return <h2 className="md:text-xl text-white">{children}</h2>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="my-4">{children}</p>,
  },
}

const DetailsSection1 = ({ data: { title, subtitle, description } }) => {
  return (
    <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0">
      <div className="text-white font-bold">
        <h1 className="text-2xl md:text-4xl text-center md:text-left uppercase siteFont">
          {title}
        </h1>
        <h3 className="text-lg md:text-2xl my-4">{subtitle}</h3>
      </div>
      <div className="mt-6 Gray md:text-lg lg:w-3/4">
        {RichTextRenderer(description.json, options)}
      </div>
      <hr className="my-6 md:my-16 xl:mx-auto" />
    </div>
  )
}

export default DetailsSection1
